<template>
  <div class="deleted-account">
    <img
      class="deleted-account__logo"
      :src="require('images/voicemod-white.svg')"
    >
    <div class="deleted-account__content">
      <img
        :src="require('images/good-bye.png')"
        class="deleted-account__image"
      >
      <h1
        class="deleted-account__title"
      >
        {{ $t('deletedAccount.title') }}
      </h1>
      <h3 class="deleted-account__text">
        {{ $t('deletedAccount.text') }}
      </h3>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" src="./DeletedAccountView.scss" />